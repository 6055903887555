// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://as-demo-test-backend-0001.azurewebsites.net/api/',
  CLIENT_ID:'e88cc5b8-86a3-4c95-bbff-64de0e7a1a20',
  SCOPES:'api://b59cff41-86e4-4c43-9048-12097eb0d311/access_as_user',
  AUTHORITY:'https://login.microsoftonline.com/f25493ae-1c98-41d7-8a33-0be75f5fe603',
  appInsights: {
    instrumentationKey: 'c46ee0cc-68c6-4fd1-a110-0afb4cfb1ace'
  },
  //API_URL: 'http://localhost:7044/api/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
