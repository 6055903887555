<div class="page-container">
    <div class="options-container">
        <mat-select id="factory" [(ngModel)]="selectedFactoryId" (selectionChange)="selectFactory()">
            <mat-option *ngFor="let factory of authorizedFactories" [value]="factory.id">{{factory.name}}</mat-option>
        </mat-select>
    </div>

    <div class="main-container">
        <div class="tree-container">
            <span class="title">{{ 'MISSING CAUSES' | translate }}</span>
            <app-tree-data id="missingCauseField"
                            [missingCauseList]="missingCausesList"
                            [currentSelectedMissingCause]="selectedMissingCause"
                            [treeType]="'MissingCause'"
                            (toggleEnabledEvent)="toggleEnabled($event)"
                            (selectEvent)="selectMissingCause($event)"
                            (addEvent)="openAddMissingCausePopup($event)"
                            (editEvent)="openEditMissingCausePopup($event)">
            </app-tree-data>
        </div>
    </div>
</div>

<app-tree-element-popup [isPopupOpen]="hasMissingCauseToChange"
                        [selectedElement]="missingCauseToModify"
                        [selectedFactoryId]="selectedFactoryId ?? -2"
                        [parentId]="selectedParentId"
                        [elementType]="'MissingCause'"
                        (closeMissingCausePopupEvent)="closeMissingCausePopup($event)">
</app-tree-element-popup>

<div class="spinner-container" *ngIf="isSavingData">
    <mat-spinner></mat-spinner>
</div>