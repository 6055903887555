<app-header [loginDisplay]="loginDisplay" (logoutEvent)="logout()" (loginEvent)="login()"></app-header>

<router-outlet *ngIf="!isIframe"></router-outlet>

<div class="lang-selector">
  <svg width="15" height="15" viewBox="0 0 116 116" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M57.64 113.29C88.3691 113.29 113.28 88.3794 113.28 57.6503C113.28 26.9211 88.3691 2.01025 57.64 2.01025C26.9109 2.01025 2 26.9211 2 57.6503C2 88.3794 26.9109 113.29 57.64 113.29Z" stroke-width="3.77" stroke-linecap="round"/>
    <path d="M57.6399 113.29C75.0093 113.29 89.0899 88.3794 89.0899 57.6503C89.0899 26.9211 75.0093 2.01025 57.6399 2.01025C40.2706 2.01025 26.1899 26.9211 26.1899 57.6503C26.1899 88.3794 40.2706 113.29 57.6399 113.29Z" stroke-width="3.77" stroke-linecap="round"/>
    <path d="M57.6396 2V113.29" stroke-width="3.77" stroke-linecap="round"/>
    <path d="M4.40967 40.71H110.86" stroke-width="3.77"/>
    <path d="M4.40967 74.5801H110.86" stroke-width="3.77"/>
  </svg>
  <div class="lang-options">
    <span *ngFor="let lang of translate.getLangs()" (click)="setLanguage(lang)" [ngClass]="{'active': lang === translate.currentLang}">{{lang}}</span>
  </div>
</div>
