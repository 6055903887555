import { ProductionZone } from "./productionZone.model";
import { Workcell } from "./workcell.model";

export enum LineType {
    Main,
    Sub
}

export class ProductionLine {

    name: string;
    productionZones: ProductionZone[];
    secondaryLines: ProductionLine[];
    primaryLineId?: number;
    factoryId?: number;
    id?:number;
    code:string;
    nextWorkcells: Workcell[];
    lineType:LineType;
    isDefaultLine:boolean;

      

    constructor(name: string, productionZones: ProductionZone[], secondaryLines: ProductionLine[], primaryLineId: number, factoryId: number, code:string, lineType:LineType, nextWorkcells:Workcell[], isDefaultLine:boolean, id?:number) {
        this.name = name;
        this.productionZones = productionZones;
        this.secondaryLines = secondaryLines;
        this.primaryLineId = primaryLineId;
        this.factoryId = factoryId;  
        this.id = id;
        this.code = code;
        this.lineType = lineType;
        this.nextWorkcells = nextWorkcells;
        this.isDefaultLine = isDefaultLine;
    }
}