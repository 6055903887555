<div class="options-container">
    <mat-select id="factory" [(ngModel)]="selectedFactoryId" (selectionChange)="selectFactory()">
        <mat-option *ngFor="let factory of authorizedFactories" [value]="factory.id">{{factory.name}}</mat-option>
    </mat-select>
    <mat-select id="line" [(ngModel)]="selectedLineId" (selectionChange)="selectLine()">
        <mat-option *ngFor="let line of authorizedLines" [value]="line.id">{{line.name}}</mat-option>
    </mat-select>
</div>

<app-tree-data id="functionField"
                [functionsList]="functionsList">
</app-tree-data>
