import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-demo-button',
  templateUrl: './demo-button.component.html',
  styleUrls: ['./demo-button.component.less']
})
export class DemoButtonComponent implements OnInit {

  @Input() text:string = "No text";
  @Input() type:string = "btn-big";
  @Input() color:string = "tertiary";

  @Input() url:string = "";


  constructor() { }

  ngOnInit(): void {
  }

}
