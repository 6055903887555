import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Factory } from 'src/app/models/factory.model';
import { LineType, ProductionLine } from 'src/app/models/productionLine.model';
import { Workcell } from 'src/app/models/workcell.model';
import { ProductionZone } from 'src/app/models/productionZone.model';
import { TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TimeZone } from 'src/app/models/timeZone.model';
import { MatLine } from '@angular/material/core';
import { environment } from 'src/environments/environment';
import { UserRole } from 'src/app/models/userRole.model';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-production-line-settings-page',
  templateUrl: './production-line-settings-page.component.html',
  styleUrls: ['./production-line-settings-page.component.less']
})
export class ProductionLineSettingsPageComponent implements OnInit {

  private readonly primaryLine = 'PRIMARY LINE';
  private readonly secondaryLine = 'SECONDARY LINE';

  @ViewChild('nameInput') nameInput!: ElementRef;

  @HostListener('window:keydown.escape') escapeEvent() { this.closeCreatePopUp(); }
  //@HostListener('window:keydown.enter') enterEvent() { if(this.popUpType != ""){this.create();} }

  //apiUrl:string =  "https://aswetstdemoback.azurewebsites.net/api/";
  apiUrl:string =  environment.API_URL;
  isAuth:boolean = false;
  isLoadingAuth:boolean = true;

  factories!:Factory[];

  selectedFactory!:Factory;
  selectedProductionLine!:ProductionLine;
  selectedSecondaryLine!:ProductionLine;
  selectedProductionZone!:ProductionZone;
  selectedSecondaryZone!:ProductionZone;

  currentFactoryWorkcells:any[] = [];

  hasSelectedFactory:boolean = false;
  hasSelectedProductionLine:boolean = false;
  hasSelectedSecondaryLine:boolean = false;
  hasSelectedProductionZone:boolean = false;
  hasSelectedSecondaryZone:boolean = false;

  createForm = new FormGroup({
    nameInput : new FormControl(''),
    timezone : new FormControl(''),
    country : new FormControl(''),
    factoryId : new FormControl(''),
    brandFactoryId : new FormControl(''),
    typeWorkcell : new FormControl(),
    nextWorkcell : new FormControl(),
    lineCode : new FormControl(''),
    enableMPProblemSolving : new FormControl(),
    displayRTAlias: new FormControl(),
    autoConfirmDeviations: new FormControl(),
    deviationProblemDetailEnabled: new FormControl(),
    deviationLocationEnabled: new FormControl(),
    operatorScanningEnabled: new FormControl(),
    isDefaultLine: new FormControl(),
    allowUnknownOperator:new FormControl(),
  });

  popUpFunction = "";
  popUpType = "";
  popUpElement: any;
  popUpNextWorkcellsList:any[] = [];

  timezones: TimeZone[] = [];

  isSuperAdmin:boolean = false;

  constructor(private httpClient:HttpClient, private translate: TranslateService, private authService:MsalService) { }

  ngOnInit(): void {
    this.isAuthorized();

    this.createForm.get('operatorScanningEnabled')?.valueChanges.subscribe((isChecked) => {
      if (isChecked) {
        this.createForm.get('allowUnknownOperator')?.enable();
      } else {
        this.createForm.get('allowUnknownOperator')?.disable();
      }
    });
  }

  isAuthorized(){
    this.httpClient.get<UserRole[]>(this.apiUrl + "UserRole/ByUserId/" + encodeURIComponent(this.authService.instance.getActiveAccount()?.username!)).subscribe(
      (res: UserRole[]) => {
        console.log("list of role me",res);
        this.isLoadingAuth = false;

        res.forEach((userRole:UserRole) => {
          if(userRole.role?.administrateData)
          {
            this.isAuth = true;
          }

          if(userRole.roleId === 0)
          {
            this.isSuperAdmin = true;
          }
        });

        if(this.isAuth){
          this.getFactories();
          this.getTimeZones();
        }
      }
    );
  }

  onDropZone(event: CdkDragDrop<ProductionZone []>){
    moveItemInArray(
      event.container.data, event.previousIndex, event.currentIndex
    );
    this.updateOrderZone(this.selectedProductionLine);
  }
  onDropSecondaryZone(event: CdkDragDrop<ProductionZone []>){
    moveItemInArray(
      event.container.data, event.previousIndex, event.currentIndex
    );
    this.updateOrderZone(this.selectedSecondaryLine);
  }
  onDropWorkcell(event: CdkDragDrop<Workcell []>){
    moveItemInArray(
      event.container.data, event.previousIndex, event.currentIndex
    );
    this.updateOrderWorkcell(this.selectedProductionZone);
  }
  onDropSecondaryWorkcell(event: CdkDragDrop<Workcell []>){
    moveItemInArray(
      event.container.data, event.previousIndex, event.currentIndex
    );
    this.updateOrderWorkcell(this.selectedSecondaryZone);
  }

  updateOrderZone(line:ProductionLine){
    line.productionZones.forEach((zone, index) => {
      zone.order = index;
    });
    this.httpClient.put<ProductionLine>(this.apiUrl + 'ProductionLine', line).subscribe(
        res => {
        }
    );
  }
  updateOrderWorkcell(zone:ProductionZone){
    zone.workcells.forEach((workcell, index) => {
      workcell.order = index;
    });
    this.httpClient.put<ProductionZone>(this.apiUrl + 'ProductionZone', zone).subscribe(
        res => {
        }
    );
  }

  sortZone(zones:ProductionZone[]){
    zones.sort((a:any, b:any) => {
        if (a.order < b.order) {
            return -1;
        }
        if (a.order > b.order) {
            return 1;
        }
        return 0;
      });
  }
  sortWorkcell(workcells:Workcell[]){
    workcells.sort((a:any, b:any) => {
        if (a.order < b.order) {
            return -1;
        }
        if (a.order > b.order) {
            return 1;
        }
        return 0;
      });
  }

  selectFactory(f:Factory){
    this.selectedFactory = f;
    this.hasSelectedFactory = true;
    this.hasSelectedProductionLine = false;
    this.clearProductionLine();

    this.hasSelectedSecondaryLine = false;
    this.clearSecondaryLine();

    this.hasSelectedProductionZone = false;
    this.clearProductionZone();

    this.hasSelectedSecondaryZone = false;
    this.clearSecondaryZone();

    this.setCurrentFactoryWorkcells();
  }
  selectProductionLine(pl:ProductionLine){
    this.selectedProductionLine = pl;
    this.hasSelectedProductionLine = true;

    this.hasSelectedProductionZone = false;
    this.clearProductionZone();

    this.hasSelectedSecondaryZone = false;
    this.clearSecondaryZone();

    this.sortZone(this.selectedProductionLine.productionZones);
  }
  selectSecondaryLine(sl:ProductionLine){
    this.selectedSecondaryLine = sl;
    this.hasSelectedSecondaryLine = true;
    this.hasSelectedSecondaryZone = false;
    this.clearSecondaryZone();
    this.sortZone(this.selectedSecondaryLine.productionZones);
  }
  selectProductionZone(z:ProductionZone){
    this.selectedProductionZone = z;
    this.hasSelectedProductionZone = true;

    this.sortWorkcell(this.selectedProductionZone.workcells);
  }
  selectSecondaryZone(z:ProductionZone){
    this.selectedSecondaryZone = z;
    this.hasSelectedSecondaryZone = true;

    this.sortWorkcell(this.selectedSecondaryZone.workcells);
  }

  openCreatePopUp(type: string){
    this.createForm.reset();
    this.popUpType = type;
    this.popUpFunction = "ADD";
    this.createForm.setValue({
      nameInput: "",
      timezone : "",
      country : "",
      factoryId : "",
      brandFactoryId : "",
      typeWorkcell: false,
      nextWorkcell: "",
      lineCode:"",
      enableMPProblemSolving:false,
      displayRTAlias:false,
      autoConfirmDeviations:false,
      deviationProblemDetailEnabled:false,
      deviationLocationEnabled:false,
      operatorScanningEnabled:false,
      isDefaultLine:false,
      allowUnknownOperator:false,
    });
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.nameInput.nativeElement.focus();
    },100);
  }
  closeCreatePopUp(){
    this.popUpType = "";
    this.popUpElement = null;
    this.popUpNextWorkcellsList = [];
  }
  openModifyPopUp(type: string, element: any, event:MouseEvent){
    event.stopImmediatePropagation();
    this.createForm.reset();
    this.createForm.setValue({
      nameInput: element?.name || "",
      timezone : element?.timeZoneDisplayName || "",
      country : "",
      factoryId : element?.factoryId || "",
      brandFactoryId : element?.brandFactoryId || "",
      typeWorkcell: element?.isCheckpoint || false,
      nextWorkcell: element.nextWorkcellId || "",
      lineCode:element?.code || "",
      enableMPProblemSolving:element?.missingPartProblemSolvingEnabled || false,
      displayRTAlias:element?.displayRTAlias || false,
      autoConfirmDeviations:element?.autoConfirmDeviations || false,
      deviationProblemDetailEnabled:element?.deviationProblemDetailEnabled || false,
      deviationLocationEnabled:element?.deviationLocationEnabled || false,
      operatorScanningEnabled:element?.operatorScanningEnabled || false,
      isDefaultLine:element?.isDefaultLine || false,
      allowUnknownOperator:element?.allowUnknownOperator || false,
    });
    this.popUpFunction = "MODIFY";
    this.popUpType = type;
    this.popUpElement = element;

    if(type === this.primaryLine || type === this.secondaryLine){
      element.nextWorkcells.forEach((w:Workcell) => {
        const fullWorkcell = this.currentFactoryWorkcells.find(x=>x.workcell.id === w.id);

        if(fullWorkcell != null)
        {
          this.popUpNextWorkcellsList.push(fullWorkcell);
        }
      });
      this.setCurrentFactoryWorkcells();
    }
      
    console.log("next workcell list", this.popUpNextWorkcellsList);

    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.nameInput.nativeElement.focus();
    },100);
  }
  openDeletePopUp(type: string, element: any, event:MouseEvent){
    event.stopImmediatePropagation();
    this.popUpFunction = "DELETE";
    this.popUpType = type;
    this.popUpElement = element;
  }
  openRestorePopUp(type: string, element: any, event:MouseEvent){
    event.stopImmediatePropagation();
    this.popUpFunction = "RESTORE";
    this.popUpType = type;
    this.popUpElement = element;
  }

  create(){
    if(this.createForm.value.nameInput === null || this.createForm.value.nameInput === undefined || this.createForm.value.nameInput === ""){
      return;
    }
    if(this.popUpType === "FACTORY"){
      const newFactory:Factory = {
        "name": this.createForm.value.nameInput!,
        "productionLines": [],
        "timeZoneDisplayName":this.createForm.value.timezone!,
        "timeZoneId":this.getTimeZonesId(this.createForm.value.timezone!),
        "brandFactoryId":this.createForm.value.brandFactoryId!,
        "factoryId":this.createForm.value.factoryId!,
        "missingPartProblemSolvingEnabled":this.createForm.value.enableMPProblemSolving,
        "displayRTAlias":this.createForm.value.displayRTAlias,
        "autoConfirmDeviations":this.createForm.value.autoConfirmDeviations,
        "deviationProblemDetailEnabled":this.createForm.value.deviationProblemDetailEnabled,
        "deviationLocationEnabled":this.createForm.value.deviationLocationEnabled,
        "deviationLocationFileName":"",
        "operatorScanningEnabled":this.createForm.value.operatorScanningEnabled,
        "allowUnknownOperator":this.createForm.value.operatorScanningEnabled ? this.createForm.value.allowUnknownOperator : false,
      }

      this.httpClient.post<Factory>(this.apiUrl + 'Factory', newFactory).subscribe(
        res => {
          this.getFactories();
        }
      );
    }
    else if(this.popUpType === this.primaryLine){
      const nextWorkcellsList:Workcell[] = [];
      this.popUpNextWorkcellsList.forEach(item => {
        nextWorkcellsList.push(item.workcell);
      });
      const newLine:ProductionLine = {
        "name": this.createForm.value.nameInput!,
        "productionZones": [],
        "secondaryLines": [],
        "factoryId": this.selectedFactory.id,
        "nextWorkcells": nextWorkcellsList,
        "code":this.createForm.value.lineCode!,
        "lineType":LineType.Main,
        "isDefaultLine":this.createForm.value.isDefaultLine
      }
      this.httpClient.post<ProductionLine>(this.apiUrl + 'ProductionLine', newLine).subscribe(
        res => {
          this.selectedFactory.productionLines.push(res);
        }
      );
    }
    else if(this.popUpType === this.secondaryLine){
      const nextWorkcellsList:Workcell[] = [];
      this.popUpNextWorkcellsList.forEach(item => {
        nextWorkcellsList.push(item.workcell);
      });
      const newSecondaryLine:ProductionLine = {
        "name": this.createForm.value.nameInput!,
        "productionZones": [],
        "secondaryLines": [],
        "factoryId": this.selectedFactory.id,
        "nextWorkcells": nextWorkcellsList,
        "code":this.createForm.value.lineCode!,
        "lineType":LineType.Sub,
        "isDefaultLine":this.createForm.value.isDefaultLine
      }
      console.log("nextworkcell", this.createForm.value.nextWorkcell);
      console.log("future secondary line", newSecondaryLine);
      this.httpClient.post<ProductionLine>(this.apiUrl + 'ProductionLine', newSecondaryLine).subscribe(
        res => {
          this.selectedFactory.productionLines.push(res);
          console.log("new secondary line", res);
        }
      );
    }
    else if(this.popUpType === "ZONE"){
      const newZone:ProductionZone = {
        "name": this.createForm.value.nameInput!,
        "order": this.selectedProductionLine.productionZones.length,
        "workcells": [],
        "productionLineId": this.selectedProductionLine.id
      };
      this.httpClient.post<ProductionZone>(this.apiUrl + 'ProductionZone', newZone).subscribe(
        resZone => {
          this.selectedProductionLine.productionZones.push(resZone);
          const newWorkcell:Workcell = {
            "name": this.createForm.value.nameInput!,
            "order": 0,
            "productionZoneId": resZone.id,
            "isCheckpoint": false,
            "disabled": false
          }
          this.httpClient.post<Workcell>(this.apiUrl + 'Workcell', newWorkcell).subscribe(
            resWorkcell => {
              this.selectedProductionLine.productionZones.find(x=> x.id === resZone.id)?.workcells.push(resWorkcell);
            }
          );
        }
      );
    }
    else if(this.popUpType === "SECONDARYZONE"){
      const newZone:ProductionZone = {
        "name": this.createForm.value.nameInput!,
        "order": this.selectedSecondaryLine.productionZones.length,
        "workcells": [],
        "productionLineId": this.selectedSecondaryLine.id
      };
      this.httpClient.post<ProductionZone>(this.apiUrl + 'ProductionZone', newZone).subscribe(
        resZone => {
          this.selectedSecondaryLine.productionZones.push(resZone);
          const newWorkcell:Workcell = {
            "name": this.createForm.value.nameInput!,
            "order": 0,
            "productionZoneId": resZone.id,
            "isCheckpoint":false,
            "disabled": false
          }
          this.httpClient.post<Workcell>(this.apiUrl + 'Workcell', newWorkcell).subscribe(
            resWorkcell => {
              this.selectedSecondaryLine.productionZones.find(x=> x.id === resZone.id)?.workcells.push(resWorkcell);
            }
          );
        }
      );
    }
    else if(this.popUpType === "WORKCELL"){
      const newWorkcell:Workcell = {
        "name": this.createForm.value.nameInput!,
        "order": this.selectedProductionZone.workcells.length,
        "productionZoneId": this.selectedProductionZone.id,
        "isCheckpoint": this.createForm.value.typeWorkcell,
        "disabled": false
      }
      console.log("newWorkcell", newWorkcell);
      this.httpClient.post<Workcell>(this.apiUrl + 'Workcell', newWorkcell).subscribe(
        res => {
          this.selectedProductionZone.workcells.push(res);
        }
      );
    }
    else if(this.popUpType === "SECONDARYWORKCELL"){
      const newWorkcell:Workcell = {
        "name": this.createForm.value.nameInput!,
        "order": this.selectedSecondaryZone.workcells.length,
        "productionZoneId": this.selectedSecondaryZone.id,
        "isCheckpoint": this.createForm.value.typeWorkcell,
        "disabled": false
      }
      this.httpClient.post<Workcell>(this.apiUrl + 'Workcell', newWorkcell).subscribe(
        res => {
          this.selectedSecondaryZone.workcells.push(res);
        }
      );
    }
    this.closeCreatePopUp();
  }

  modify(){
    if(this.createForm.value.nameInput === null || this.createForm.value.nameInput === undefined || this.createForm.value.nameInput === "" || this.popUpElement == null){
      return;
    }
    if(this.popUpType === "FACTORY"){
      this.popUpElement.name = this.createForm.value.nameInput!;
      this.popUpElement.timeZoneDisplayName = this.createForm.value.timezone;
      this.popUpElement.timeZoneId = this.getTimeZonesId(this.createForm.value.timezone!);
      this.popUpElement.factoryId = this.createForm.value.factoryId;
      this.popUpElement.brandFactoryId = this.createForm.value.brandFactoryId;
      this.popUpElement.missingPartProblemSolvingEnabled = this.createForm.value.enableMPProblemSolving;
      this.popUpElement.displayRTAlias = this.createForm.value.displayRTAlias;
      this.popUpElement.autoConfirmDeviations = this.createForm.value.autoConfirmDeviations;
      this.popUpElement.deviationProblemDetailEnabled = this.createForm.value.deviationProblemDetailEnabled;
      this.popUpElement.deviationLocationEnabled = this.createForm.value.deviationLocationEnabled;
      this.popUpElement.operatorScanningEnabled = this.createForm.value.operatorScanningEnabled;
      this.popUpElement.allowUnknownOperator = this.createForm.value.operatorScanningEnabled ? this.createForm.value.allowUnknownOperator : false;

      const elementToSend = Object.assign({}, this.popUpElement);
      elementToSend.productionLines = null;

      this.httpClient.put<Factory>(this.apiUrl + 'Factory', elementToSend).subscribe(
        res => {
        }
      );
    }
    else if(this.popUpType === this.primaryLine){
      this.popUpElement.name = this.createForm.value.nameInput!;
      this.popUpElement.code = this.createForm.value.lineCode!;

      this.popUpElement.isDefaultLine = this.createForm.value.isDefaultLine!;

      const nextWorkcellsList:Workcell[] = [];
      this.popUpNextWorkcellsList.forEach(item => {
        nextWorkcellsList.push(item.workcell);
      });
      this.popUpElement.nextWorkcells = nextWorkcellsList;
      this.popUpElement.nextWorkcellId = null;
      
      this.httpClient.put<ProductionLine>(this.apiUrl + 'ProductionLine', this.popUpElement).subscribe(
        res => {
        }
      );
    }
    else if(this.popUpType === this.secondaryLine){
      this.popUpElement.name = this.createForm.value.nameInput!;
      this.popUpElement.code = this.createForm.value.lineCode!;

      this.popUpElement.isDefaultLine = this.createForm.value.isDefaultLine!;
      
      const nextWorkcellsList:Workcell[] = [];
        this.popUpNextWorkcellsList.forEach(item => {
          nextWorkcellsList.push(item.workcell);
        });
      this.popUpElement.nextWorkcells = nextWorkcellsList;

      this.popUpElement.nextWorkcellId = null;
      console.log("element", this.popUpElement)

      this.httpClient.put<ProductionLine>(this.apiUrl + 'ProductionLine', this.popUpElement).subscribe(
        res => {
          console.log("res", res);
        }
      );
    }
    else if(this.popUpType === "ZONE" || this.popUpType === "SECONDARYZONE"){
      this.popUpElement.name = this.createForm.value.nameInput!;
      this.httpClient.put<ProductionZone>(this.apiUrl + 'ProductionZone', this.popUpElement).subscribe(
        res => {
        }
      );
    }
    else if(this.popUpType === "WORKCELL" || this.popUpType === "SECONDARYWORKCELL"){
      this.popUpElement.name = this.createForm.value.nameInput!;
      this.popUpElement.isCheckpoint = this.createForm.value.typeWorkcell;
      this.httpClient.put<Workcell>(this.apiUrl + 'Workcell', this.popUpElement).subscribe(
        res => {
        }
      );
    }
    this.closeCreatePopUp();
  }

  addNextWorkcell(element:any){
    if(this.createForm.value.nextWorkcell != null)
    {
      this.popUpNextWorkcellsList.push(element);
    }
    
    this.createForm.controls.nextWorkcell.setValue("");
    this.setCurrentFactoryWorkcells();
  }

  removeNextWorkcell(index:number){
    this.popUpNextWorkcellsList.splice(index, 1);
  }

  deleteElement(){
    if(this.popUpElement == null){
      return;
    }

    if(this.popUpType === "FACTORY"){
      this.httpClient.delete<Factory>(this.apiUrl + 'Factory/' + this.popUpElement.id).subscribe(
        res => {
          this.getFactories();
          this.closeCreatePopUp();
        }
      );
    }
    else if(this.popUpType === this.primaryLine){
      this.httpClient.delete<ProductionLine>(this.apiUrl + 'ProductionLine/' + this.popUpElement.id).subscribe(
        res => {
          this.selectedFactory.productionLines.splice(this.selectedFactory.productionLines.indexOf(this.popUpElement), 1);
          this.closeCreatePopUp();
        }
      );
    }
    else if(this.popUpType === this.secondaryLine){
      console.log("element before delete", this.popUpElement);
      this.httpClient.delete<ProductionLine>(this.apiUrl + 'ProductionLine/' + this.popUpElement.id).subscribe(
        res => {
          this.selectedFactory.productionLines.splice(this.selectedFactory.productionLines.indexOf(this.popUpElement), 1);
          this.closeCreatePopUp();
        }
      );
    }
    else if(this.popUpType === "ZONE"){
      this.httpClient.delete<ProductionZone>(this.apiUrl + 'ProductionZone/' + this.popUpElement.id).subscribe(
        res => {
          this.selectedProductionLine.productionZones.splice(this.selectedProductionLine.productionZones.indexOf(this.popUpElement), 1);
          this.updateOrderZone(this.selectedProductionLine);
          this.closeCreatePopUp();
        }
      );
    }
    else if(this.popUpType === "SECONDARYZONE"){
      this.httpClient.delete<ProductionZone>(this.apiUrl + 'ProductionZone/' + this.popUpElement.id).subscribe(
        res => { 
          this.selectedSecondaryLine.productionZones.splice(this.selectedSecondaryLine.productionZones.indexOf(this.popUpElement), 1);
          this.updateOrderZone(this.selectedSecondaryLine);
          this.closeCreatePopUp();
        }
      );
    }
    else if(this.popUpType === "WORKCELL"){
      this.popUpElement.disabled = true;
      this.httpClient.put<Workcell>(this.apiUrl + 'Workcell', this.popUpElement).subscribe(
        res => {
          this.closeCreatePopUp();
        }
      );
    }
    else if(this.popUpType === "SECONDARYWORKCELL"){
      this.httpClient.delete<Workcell>(this.apiUrl + 'Workcell/' + this.popUpElement.id).subscribe(
        res => {
          this.selectedSecondaryZone.workcells.splice(this.selectedSecondaryZone.workcells.indexOf(this.popUpElement), 1);
          this.updateOrderWorkcell(this.selectedSecondaryZone);
          this.closeCreatePopUp();
        }
      );
    }
    
  }

  restoreElement(){
    if(this.popUpElement == null){
      return;
    }
    
    if(this.popUpType === "WORKCELL"){
      this.popUpElement.disabled = false;
      this.httpClient.put<Workcell>(this.apiUrl + 'Workcell', this.popUpElement).subscribe(
        res => {
          this.closeCreatePopUp();
        }
      );
    }
  }

  getFactories(){
    this.httpClient.get<Factory[]>(this.apiUrl + "Factory/Structure").subscribe(
      (res: any) => {
        this.factories = res;
        console.log(res);
      }
    );
  }
  getTimeZones(){
    this.httpClient.get<TimeZone[]>(this.apiUrl + "TimeZone").subscribe(
      (res: any) => {
        this.timezones = res;
      }
    );
  }
  getTimeZonesId(displayName:string):string{
    if(this.timezones != null){
      return this.timezones.find(x => x.displayName === displayName)?.id || "";
    }
    return "";
  }
  getZoneId(displayName:string):number{
    console.log("zones", this.selectedProductionLine.productionZones);
    console.log("new zone id", this.selectedProductionLine.productionZones.find((x:any) => x.name === this.createForm.value.nextWorkcell)?.id || -1);
    return this.selectedProductionLine.productionZones.find((x:any) => x.name === this.createForm.value.nextWorkcell)?.id || -1;
  }
  getPrimaryLines(){
    return this.selectedFactory.productionLines.filter(l => l.lineType === LineType.Main);
  }
  getSecondaryLines(){
    return this.selectedFactory.productionLines.filter(l => l.lineType === LineType.Sub);
  }
  setCurrentFactoryWorkcells(){
    this.currentFactoryWorkcells = [];

    this.factories.forEach(factory => {
      factory.productionLines.forEach(pl => {
        if(pl.id !== this.popUpElement?.id){
          pl.productionZones.forEach(zone => {
            zone.workcells.forEach(workcell => {
              if(this.popUpNextWorkcellsList.find((x:Workcell) => x.id === workcell.id) == null)
              {
                this.currentFactoryWorkcells.push({factory:factory, line:pl, zone:zone, workcell:workcell});
              }
            });
          });
        }
      })
    });
  }

  clearProductionLine(){
    this.selectedProductionLine = {
      "name": "",
      "productionZones": [],
      "secondaryLines": [],
      "factoryId": undefined,  
      "id": undefined,
      "code":"",
      "nextWorkcells": [],
      "lineType":LineType.Main,
      "isDefaultLine":false
    }
  }
  clearSecondaryLine(){
    this.selectedSecondaryLine = {
      "name": "",
      "productionZones": [],
      "secondaryLines": [],
      "factoryId": undefined,  
      "id": undefined,
      "code":"",
      "nextWorkcells": [],
      "lineType":LineType.Sub,
      "isDefaultLine":false
    }
  }
  clearProductionZone(){
    this.selectedProductionZone = {
      "name": "",
      "order": 0,
      "workcells": [],
      "productionLineId": undefined
    }
  }
  clearSecondaryZone(){
    this.selectedSecondaryZone = {
      "name": "",
      "order": 0,
      "workcells": [],
      "productionLineId": undefined
    }
  }

  getPopupTitle(): any{
    return this.translate.instant('ADMIN.' + this.popUpFunction + ' ' + this.popUpType);
  }
  getPopupText(): any{
    return this.translate.instant('ADMIN.' + this.popUpFunction + ' TEXT');
  }
  getWorkcellName(workcellId:number){
    return this.currentFactoryWorkcells.find(x => x.id === workcellId)?.name;
  }
}
