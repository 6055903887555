import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FactoryView } from 'src/app/models/factoryViewModel.model';
import { FaultType } from 'src/app/models/faultType.model';
import { FaultTypeView } from 'src/app/models/faultTypeView.model';
import { ProductionLineView } from 'src/app/models/productionLineView.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-administrate-list-fault-types',
  templateUrl: './administrate-list-fault-types.component.html',
  styleUrl: './administrate-list-fault-types.component.less'
})
export class AdministrateListFaultTypesComponent {
  
  apiUrl = environment.API_URL;

  selectedFactoryId: number | null = null;
  selectedLineId: number | null = null;
  authorizedFactories: FactoryView[] = [];
  authorizedLines: ProductionLineView[] = [];
  linkedLines: Set<number> = new Set();
  
  faultTypesList: FaultType[] = [];

  hasFaultTypeToChange = false;
  selectedFaultType: FaultType | null = null;
  faultTypeToModify: FaultType | null = null;
  selectedParent: FaultTypeView | null = null;
  isSavingData = false;

  constructor(
    private httpClient: HttpClient) {
  }

  ngOnInit() {
    this.getAuthorizedFactories(2);
  }

  selectFactory() {
    this.authorizedLines = this.authorizedFactories.find(x => x.id === this.selectedFactoryId)?.productionLines || [];
    if (this.authorizedLines.length > 0) {
      this.selectedLineId = this.authorizedLines[0].id ?? null;
    } else {
      this.selectedLineId = null;
    }
    this.selectLine();
  }

  selectLine() {
    this.getFaultTypesList(this.selectedLineId);
  }

  getAuthorizedFactories(page: number) {
    this.httpClient.get<FactoryView[]>(`${this.apiUrl}Factory/ByPageType/${page}`).subscribe(
      {
        next: (res) => {
          this.authorizedFactories = res;
          this.selectedFactoryId = res[0].id ?? null;
          this.selectFactory();
        }
      }
    );
  }

  getFaultTypesList(plId: number | null) {
    if (plId == null || localStorage.getItem('language') == null) {
      this.selectedFaultType = null;
      this.faultTypesList = [];
      return;
    }
    
    this.isSavingData = true;
    this.httpClient.get<FaultType[]>(`${this.apiUrl}FaultType/ByProductionLineIdFull/${plId}`).subscribe(
      {
        next: (res) => {
          this.faultTypesList = res;
          if (this.faultTypesList.find(x => x.id === this.selectedFaultType?.id) == null) {
            this.selectedFaultType = null;
          }
          this.isSavingData = false;
        },
        error: (e) => {
          this.faultTypesList = [];
          this.isSavingData = false;
        }
      }
    );
  }

  toggleEnabled(ftId: number) {
    const ft = this.faultTypesList.find(x => x.id === ftId);
    if (ft == null) { return; }

    this.isSavingData = true;
    const method = ft.disabled ? "Enable" : "Disable";
    this.httpClient.put<FaultType[]>(`${this.apiUrl}FaultType/${method}/${ftId}/${this.selectedLineId}`, null).subscribe(
      {
        next: (res) => {
          this.faultTypesList = res;
          this.isSavingData = false;
        },
        error: (e) => {
          this.isSavingData = false;
        }
      }
    );
  }

  selectFaultType(fgId: number) {
    const ft = this.faultTypesList.find(x => x.id === fgId);
    this.selectedFaultType = ft ?? null;
    if (ft == null) { return; }
    this.loadFaultTypeLines(fgId);
  }

  loadFaultTypeLines(ftId: number) {
    this.isSavingData = true;
    this.linkedLines.clear();
    this.httpClient.get<number[]>(`${this.apiUrl}FaultType/${ftId}/ProductionLines`).subscribe(
      {
        next: (res) => {
          res.forEach(line => {
            this.linkedLines.add(line);
          });
          this.isSavingData = false;
        },
        error: (e) => {
          this.isSavingData = false;
        }
      }
    );
  }

  openAddFaultTypePopup(parent: FaultTypeView | null) {
    if (this.selectedLineId == null) { return; }

    this.selectedParent = parent;
    this.faultTypeToModify = null;

    this.hasFaultTypeToChange = true;
  }

  openEditFaultTypePopup(ftId: number) {
    const ft = this.faultTypesList.find(x => x.id === ftId);
    if (ft == null) { return; }

    this.faultTypeToModify = ft;

    this.hasFaultTypeToChange = true;
  }

  closeFaultTypePopup(faultType: FaultType | null) {
    this.hasFaultTypeToChange = false;
    this.faultTypeToModify = null;
    this.selectedParent = null;
    if (faultType != null) {
      const fgIdx = this.faultTypesList.findIndex(x => x.id === faultType.id);
      if (fgIdx !== -1) {
        this.faultTypesList[fgIdx] = faultType;
      } else {
        this.faultTypesList.push(faultType);
      }
      this.faultTypesList = [...this.faultTypesList];
      this.selectFaultType(faultType.id);
    }
  }

  onCheckChange(lineId: number, event: Event) {
    if (this.selectedFaultType === null) { return; }

    const target = event.target as HTMLInputElement;
    this.isSavingData = true;
    target.checked = !target.checked; // Checked state will be determined by the contents of linkedLines after the request

    if (!target.checked) {
      this.httpClient.post<any>(`${this.apiUrl}FaultType/${this.selectedFaultType.id}/ProductionLine/${lineId}`, null).subscribe(
        {
          next: (res) => {
            this.isSavingData = false;
            this.linkedLines.add(lineId);
          },
          error: (e) => {
            this.isSavingData = false;
          }
        }
      );
    } else {
      this.httpClient.delete<any>(`${this.apiUrl}FaultType/${this.selectedFaultType.id}/ProductionLine/${lineId}`).subscribe(
        {
          next: (res) => {
            this.isSavingData = false;
            this.linkedLines.delete(lineId);
          },
          error: (e) => {
            this.isSavingData = false;
          }
        }
      );
    }
  }

  areAllLinesChecked(factoryId: number) {
    const factory = this.authorizedFactories.find(x => x.id === factoryId);
    if (factory == null) { return false; }

    for (const line of factory.productionLines) {
      if (!this.linkedLines.has(line.id)) {
        return false;
      }
    }

    return true;
  }

  onCheckAllLinesChange(factoryId: number, event: Event) {
    if (this.selectedFaultType === null) { return; }

    const target = event.target as HTMLInputElement;
    this.isSavingData = true;
    target.checked = !target.checked; // Checked state will be determined by the contents of linkedLines after the request

    const factory = this.authorizedFactories.find(x => x.id === factoryId);
    if (factory == null) { return; }

    if (!target.checked) {
      this.httpClient.post<any>(`${this.apiUrl}FaultType/${this.selectedFaultType.id}/Factory/${factoryId}`, null).subscribe(
        {
          next: (res) => {
            for (const line of factory.productionLines) {
              this.linkedLines.add(line.id);
            }
            this.isSavingData = false;
          },
          error: (e) => {
            this.isSavingData = false;
          }
        }
      );
    } else {
      this.httpClient.delete<any>(`${this.apiUrl}FaultType/${this.selectedFaultType.id}/Factory/${factoryId}`).subscribe(
        {
          next: (res) => {
            for (const line of factory.productionLines) {
              this.linkedLines.delete(line.id);
            }
            this.isSavingData = false;
          },
          error: (e) => {
            this.isSavingData = false;
          }
        }
      );
    }
  }
}
