export class FaultType {
    rank1Code = "";
    rank1NameEN = "";
    rank1NameFR = "";
    rank1NameNL = "";
    rank2Code = "";
    rank2NameEN = "";
    rank2NameFR = "";
    rank2NameNL = "";
    disabled = false;
    id = 0;

    constructor() {}
}