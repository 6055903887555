import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Inject, OnDestroy } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, BrowserUtils, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { Location } from "@angular/common";
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MonitoringService } from './monitoring.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy{
  apiUrl:string =  environment.API_URL;
  title = 'DeMO-Frontend';
  storedTheme: any = localStorage.getItem('theme-color');
  param = {value: 'world'};

  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();  
  
  translations:any = [];

  constructor(
    public translate: TranslateService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private httpClient:HttpClient,
    private MonitoringService: MonitoringService) {
  }

  ngOnInit(): void {
    this.setTheme("theme-neutral");
    this.isIframe = window !== window.parent && !window.opener;
    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.inProgress$.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None), takeUntil(this._destroying$)).subscribe(() => {
        this.setLoginDisplay();
        this.setActiveAccount();
        this.setLangs();
        this.reloadPage();
    });
  }

  setLangs(){
    //LANGUAGE SETTING
    this.translate.addLangs(['en', 'fr', 'nl']);

    this.translate.getLangs().forEach(lang => {
      this.httpClient.get(this.apiUrl + 'Translation/' + lang).subscribe(
        res => {
          this.translations.push([lang, res]);
        }
      );
    });

    if(localStorage.getItem('language')){
      const tmp:string = localStorage.getItem('language') || "";
      this.httpClient.get(this.apiUrl + 'Translation/' + tmp).subscribe(
        res => {
          this.translate.setTranslation(tmp, res);
          this.translate.setDefaultLang(tmp);
          this.translate.use(tmp);
        }
      );
    } else {
      this.setLanguage("en");
    }
  }

  setLanguage(lang:string){
    const translation:any = this.translations.find((x:any) => x[0]===lang);
    if(translation != null){
      this.translate.setTranslation(lang, translation[1]);
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);
      localStorage.setItem("language",lang);
    }
  }

  setTheme(theme:string){
    localStorage.setItem('theme-color', theme);
    this.storedTheme = localStorage.getItem('theme-color');
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  setActiveAccount(){
    if(this.loginDisplay){
      this.authService.instance.setActiveAccount(this.authService.instance.getAllAccounts()[0]);
    }
  }

  reloadPage(){
    const currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
    console.log("reload", [currentUrl]);
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        if (this.msalGuardConfig.authRequest) {
          this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
            .subscribe((response: AuthenticationResult) => {
              this.authService.instance.setActiveAccount(response.account);
            });
        } else {
          this.authService.loginPopup()
            .subscribe((response: AuthenticationResult) => {
              this.authService.instance.setActiveAccount(response.account);
            });
        }
      } else {
        if (this.msalGuardConfig.authRequest) {
          this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        } else {
          this.authService.loginRedirect();
        }
      }
  }
  logout() {
    this.authService.logout();
  }
  
  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
