import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { UserRole } from 'src/app/models/userRole.model';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-super-admin-page',
  templateUrl: './super-admin-page.component.html',
  styleUrls: ['./super-admin-page.component.less']
})
export class SuperAdminPageComponent implements OnInit {

  apiUrl:string = environment.API_URL;
  isAuth:boolean = false;
  isLoadingAuth:boolean = true;
  message = ""
  
  constructor(private httpClient:HttpClient, private translate: TranslateService, private authService:MsalService) { }

  ngOnInit(): void {
    this.isAuthorized();
  }

  isAuthorized(){
    this.httpClient.get<UserRole[]>(this.apiUrl + "UserRole/ByUserId/" + encodeURIComponent(this.authService.instance.getActiveAccount()?.username!)).subscribe(
      (res: UserRole[]) => {
        this.isLoadingAuth = false;

        res.forEach((userRole:UserRole) => {
          if(userRole.role?.name === "╭∩╮( •̀_•́ )╭∩╮")
          {
            this.isAuth = true;
          }
        });
      }
    );
  }

  sendNotification(){
    this.message = "";
    this.httpClient.post<any>(this.apiUrl + "Notification/RefreshPage", null).subscribe(
      (res) => {
        this.message = "Notification sent.";
      }
    );
  }

}
