interface Translation {
    nameEN: string;
    nameFR: string;
    nameNL: string;
}

interface Levels {
    level1: string;
    level2: string | null;
    level3: string | null;
}

export class Responsible {

    //LANGUAGE SETTING
    id:number | null;
    parentId:number | null;
    code:string;
    level1:string;
    level2:string | null;
    level3:string | null;
    nameEN:string | null;
    nameFR:string | null;
    nameNL:string | null;
    disabled:boolean;


    constructor(
            parentId:number | null,
            code:string,
            levels:Levels,
            names:Translation, 
            disabled:boolean, 
            id:number) {

        this.parentId = parentId;
        this.code = code;
        this.level1 = levels.level1;
        this.level2 = levels.level2;
        this.level3 = levels.level3;
        this.nameEN = names.nameEN;
        this.nameFR = names.nameFR;
        this.nameNL = names.nameNL;
        this.disabled = disabled;
        this.id = id;
    }
}