export class FaultTypeView {
    parentId: number | null = null;
    code = "";
    nameEN = "";
    nameFR = "";
    nameNL = "";
    disabled = false;
    id = 0;

    constructor() {}
}