import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Factory } from 'src/app/models/factory.model';
import { environment } from 'src/environments/environment';

interface Result {
  checks: Check[];
  apiUrl:string;
  totalStatus: string;
  totalResponseTime: number;
}
interface Check {
  name: string;
  status: string;
  responseTime: number;
  description:string;
}

@Component({
  selector: 'app-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.less']
})
export class HealthCheckComponent {
  apiUrl:string =  environment.API_URL;
  result: Result = {checks:[], apiUrl:"", totalStatus:"", totalResponseTime:0};

  constructor(private httpClient: HttpClient) {}

  ngOnInit() {
    //Check connection back without loggin
    const startCoBack = new Date().getTime();
    
    this.httpClient.get<any>(this.apiUrl + "HealthCheck").subscribe(
      {
        next: (res) => {
          this.result.checks.push({name:"GET Back response",status:"Healthy", responseTime:new Date().getTime() - startCoBack, description:""});
          this.setTotalResult();
        },
        error: (e) => {
          this.result.checks.push({name:"GET Back response",status:"Unhealthy", responseTime:new Date().getTime() - startCoBack, description:e.status + " " + e.statusText});
          this.setTotalResult();
        }
      }
    );

    //Check connection back sending data with loggin
    const startFactoryFrom = new Date().getTime();
    
    this.httpClient.get<Factory[]>(this.apiUrl + "Factory").subscribe(
      {
        next: (res) => {
          this.result.checks.push({name:"GET Factory",status:"Healthy", responseTime:new Date().getTime() - startFactoryFrom, description:""});
          this.setTotalResult();
        },
        error: (e) => {
          this.result.checks.push({name:"GET Factory",status:"Unhealthy", responseTime:new Date().getTime() - startFactoryFrom, description:e.status + " " + e.statusText});
          this.setTotalResult();
        }
      }
    );

    //Check connection back translation files
    const startTranslationFrom = new Date().getTime();

    this.httpClient.get(this.apiUrl + 'Translation/en').subscribe(
      {
        next: (res) => {
          this.result.checks.push({name:"GET en.json",status:"Healthy", responseTime:new Date().getTime() - startTranslationFrom, description:""});
          this.setTotalResult();
        },
        error: (e) => {
          this.result.checks.push({name:"GET en.json",status:"Unhealthy", responseTime:new Date().getTime() - startTranslationFrom, description:e.status + " " + e.statusText});
          this.setTotalResult();
        }
      }
    );

    this.result.apiUrl = this.apiUrl;
  }


  setTotalResult(){
    this.result.totalStatus = "Healthy";
    this.result.totalResponseTime = 0;

    this.result.checks.forEach( check => {

      if(check.status === "Unhealthy")
      {
        this.result.totalStatus = "Unhealthy";
      }
      
      this.result.totalResponseTime += check.responseTime;
    });
  }
}