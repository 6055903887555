<div class="options-container">
    <mat-select id="factory" [(ngModel)]="selectedFactoryId" (selectionChange)="selectFactory()">
        <mat-option *ngFor="let factory of authorizedFactories" [value]="factory.id">{{factory.name}}</mat-option>
    </mat-select>
</div>

<div class="tags-list-container">
    <div class="search-tag-container">
        <input type="text" placeholder="{{'SEARCH' | translate}}" [(ngModel)]="tagFilter" autocomplete="off">
        <mat-icon class="search">{{'search'}}</mat-icon>
    </div>
    <div class="tags-list">
        <span *ngFor="let tag of tagFiltered()"
                [ngClass]="{'disabled':tag.disabled}">
                {{tag.name}}
                <mat-icon 
                    class="visibility-icon small" 
                    *ngIf="!tag.disabled" title="{{'CLICK TO DISABLE' | translate}}"
                    (mousedown)="changeVisibility(tag, $event)">
                        {{'visibility'}}
                </mat-icon>
                <mat-icon 
                    class="visibility-icon small" 
                    *ngIf="tag.disabled" 
                    title="{{'CLICK TO ENABLE' | translate}}" 
                    (mousedown)="changeVisibility(tag, $event)">
                        {{'visibility_off'}}
                </mat-icon>
        </span>
    </div>
</div>

<div class="add-tag-container" *ngIf="selectedFactoryId != null">
    <input type="text" 
        placeholder="{{'ADMIN.ADD' | translate}}" 
        [(ngModel)]="newTagText"
        autocomplete="off">
    <app-demo-button text="{{ 'ADMIN.ADD' | translate}}" color="secondary" type="btn-small" (click)="createNewTag()"></app-demo-button>
    <span class="error-message" *ngIf="errorMessage != ''">{{errorMessage | translate}}</span>
</div>




