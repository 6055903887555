import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { User } from 'src/app/models/user.model';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { UserRole } from 'src/app/models/userRole.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  //apiUrl:string =  "https://as-demo-test-backend-0001.azurewebsites.net/api/";
  apiUrl:string =  environment.API_URL;

  @Input() loginDisplay:boolean = false;
  @Output() logoutEvent = new EventEmitter<any>();
  @Output() loginEvent = new EventEmitter<any>();

  activePage:string = "";
  isSuperAdmin:boolean = false;

  constructor(
    private httpClient:HttpClient,
    private authService:MsalService
  ) { }

  ngOnInit(): void {
    this.isAuthorized();
  }

  isAuthorized(){
    this.httpClient.get<UserRole[]>(this.apiUrl + "UserRole/ByUserId/" + encodeURIComponent(this.authService.instance.getActiveAccount()?.username!)).subscribe(
      (res: UserRole[]) => {
        res.forEach((userRole:UserRole) => {
          if(userRole.role?.name === "╭∩╮( •̀_•́ )╭∩╮")
          {
            this.isSuperAdmin = true;
          }
        });
      }
    );
  }
  
  getActiveAccount(): AccountInfo | null{
    return this.authService.instance.getActiveAccount();
  }

  login(){
    this.loginEvent.emit();
  } 

  logout(){
    this.logoutEvent.emit();
  }

}
