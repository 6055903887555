import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FactoryView } from 'src/app/models/factoryViewModel.model';
import { FunctionGroupView } from 'src/app/models/functionGroupView.model';
import { Language } from 'src/app/models/language.enum';
import { ProductionLine } from 'src/app/models/productionLine.model';
import { ProductionLineView } from 'src/app/models/productionLineView.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-administrate-list-functions',
  templateUrl: './administrate-list-functions.component.html',
  styleUrl: './administrate-list-functions.component.less'
})
export class AdministrateListFunctionsComponent {

  apiUrl = environment.API_URL;

  selectedFactoryId:number | null = null;
  selectedLineId:number | null = null;
  authorizedFactories:FactoryView[] = [];
  authorizedLines:ProductionLineView[] = [];
  
  functionsList:FunctionGroupView[] = [];
  functionsLoaded = false;

  constructor(
    private httpClient:HttpClient,
    private translate: TranslateService) {
  }

  ngOnInit(){
    this.getAuthorizedFactories(2);
  }

  selectFactory(){
    this.authorizedLines = this.authorizedFactories.find(x=>x.id === this.selectedFactoryId)?.productionLines || [];
    this.selectedLineId = this.authorizedLines[0].id || null;
    this.getFunctionsList(this.selectedLineId!);
  }

  selectLine(){
    this.getFunctionsList(this.selectedLineId!)
  }

  getAuthorizedFactories(page?:number){
    this.httpClient.get<FactoryView[]>(`${this.apiUrl}Factory/ByPageType/${page}`).subscribe(
      (res: FactoryView[]) => {
        this.authorizedFactories = res;
        this.selectedFactoryId = res[0].id || null;
        this.selectFactory();
      }
    );
  }

  getFunctionsList(plId:number){
    if(plId == null || localStorage.getItem('language') == null) { return;}
    
    this.functionsList = [];
    this.functionsLoaded = false;
    
    this.httpClient.get<FunctionGroupView[]>(`${this.apiUrl}FunctionGroup/ByProductionLineId/${plId}/${localStorage.getItem('language')}`).subscribe(
      (res: FunctionGroupView[]) => {
        this.functionsLoaded = true;
        this.functionsList = res;
      }
    );
  }

}
