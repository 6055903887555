import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FactoryView } from 'src/app/models/factoryViewModel.model';
import { MissingCause } from 'src/app/models/missingCause.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-administrate-list-missing-causes',
  templateUrl: './administrate-list-missing-causes.component.html',
  styleUrl: './administrate-list-missing-causes.component.less'
})
export class AdministrateListMissingCausesComponent {

  apiUrl = environment.API_URL;

  selectedFactoryId: number | null = null;
  authorizedFactories: FactoryView[] = [];
  linkedLines: Set<number> = new Set();
    
  missingCausesList:MissingCause[] = [];

  hasMissingCauseToChange = false;
  selectedMissingCause: MissingCause | null = null;
  missingCauseToModify: MissingCause | null = null;
  selectedParentId: number | null = null;
  isSavingData = false;

  constructor(
    private httpClient:HttpClient) {
  }

  ngOnInit() {
    this.getAuthorizedFactories(2);
  }

  selectFactory() {
    this.getMissingCausesList(this.selectedFactoryId);
  }

  getAuthorizedFactories(page: number) {
    this.httpClient.get<FactoryView[]>(`${this.apiUrl}Factory/ByPageType/${page}`).subscribe(
      {
        next: (res) => {
          this.authorizedFactories = res;
          this.selectedFactoryId = res[0].id ?? null;
          this.selectFactory();
        }
      }
    );
  }

  getMissingCausesList(factoryId: number | null) {
    if(factoryId == null || localStorage.getItem('language') == null) {
      this.selectedMissingCause = null;
      this.missingCausesList = [];
      return;
    }
    
    this.isSavingData = true;
    this.httpClient.get<MissingCause[]>(`${this.apiUrl}MissingCause/ByFactoryIdFull/${factoryId}`).subscribe(
      {
        next: (res) => {
          this.missingCausesList = res;
          if (this.missingCausesList.find(x => x.id === this.selectedMissingCause?.id) == null) {
            this.selectedMissingCause = null;
          }
          this.isSavingData = false;
        },
        error: (e) => {
          this.missingCausesList = [];
          this.isSavingData = false;
        }
      }
    );
  }

  toggleEnabled(mcId: number) {
    const mc = this.missingCausesList.find(x => x.id === mcId);
    if(mc == null) { return; }

    this.isSavingData = true;
    const method = mc.disabled ? "Enable" : "Disable";
    this.httpClient.put<MissingCause[]>(`${this.apiUrl}MissingCause/${method}/${mcId}/${this.selectedFactoryId!}`, null).subscribe(
      {
        next: (res) => {
          this.missingCausesList = res;
          this.isSavingData = false;
        },
        error: (e) => {
          this.isSavingData = false;
        }
      }
    );
  }

  selectMissingCause(mcId: number) {
    const mc = this.missingCausesList.find(x => x.id === mcId);
    if(mc == null) { return; }
    this.selectedMissingCause = mc;
  }

  openAddMissingCausePopup(mcId: number | null) {
    if (this.selectedFactoryId == null) { return; }

    this.hasMissingCauseToChange = true;

    this.selectedParentId = mcId;
    this.missingCauseToModify = null;
  }

  openEditMissingCausePopup(mcId: number) {
    const mc = this.missingCausesList.find(x => x.id === mcId);
    if(mc == null) { return; }

    this.hasMissingCauseToChange = true;

    this.missingCauseToModify = mc;
  }

  closeMissingCausePopup(missingCause: MissingCause | null) {
    this.hasMissingCauseToChange = false;
    this.missingCauseToModify = null;
    this.selectedParentId = null;
    if (missingCause != null) {
      const mcIdx = this.missingCausesList.findIndex(x => x.id === missingCause.id);
      if (mcIdx !== -1) {
        this.missingCausesList[mcIdx] = missingCause;
      } else {
        this.missingCausesList.push(missingCause);
      }
      this.missingCausesList = [...this.missingCausesList];
      this.selectMissingCause(missingCause.id!);
    }
  }
}
