import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
import { Role } from 'src/app/models/role.model';
import { UserRole } from 'src/app/models/userRole.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-users-roles-settings-page',
  templateUrl: './users-roles-settings-page.component.html',
  styleUrls: ['./users-roles-settings-page.component.less']
})
export class UsersRolesSettingsPageComponent implements OnInit {

  apiUrl:string =  environment.API_URL;
  isAuth:boolean = false;
  isLoadingAuth:boolean = true;
  
  roles!:Role[];

  selectedRole!: Role;
  selectedRoleUsers!:UserRole[];

  userForm = new FormGroup({
    nameInput : new FormControl('')
  });

  popUpFunction = "";
  popUpElement!: Role | null;

  constructor(private httpClient:HttpClient, private translate: TranslateService, private authService:MsalService) { }

  ngOnInit(): void {
    this.isAuthorized();
  }

  isAuthorized(){
    this.httpClient.get<UserRole[]>(this.apiUrl + "UserRole/ByUserId/" + encodeURIComponent(this.authService.instance.getActiveAccount()?.username!)).subscribe(
      (res: UserRole[]) => {
        console.log("list of role me",res);
        this.isLoadingAuth = false;

        res.forEach((userRole:UserRole) => {
          if(userRole.role?.administrateData)
          {
            this.isAuth = true;
          }
        });

        if(this.isAuth){
          this.getRoles();
        }
      }
    );
  }


  getRoles(){
    this.httpClient.get<Role[]>(this.apiUrl + "Role").subscribe(
      (res: any) => {
        this.roles = res;
        console.log(res);
      }
    );
  }

  selectRole(role:Role){
    this.httpClient.get<UserRole[]>(this.apiUrl + "UserRole/ByRoleName/" + encodeURIComponent(role.name)).subscribe(
      {
        next: (res) => {
          this.selectedRoleUsers = res;
          this.selectedRole = role;
        },
        error: (e) => {
          this.selectedRoleUsers = [];
          this.selectedRole = role;
        }
      }
    );
  }

  addUser(){
    if(this.userForm.value.nameInput == null)
    {
      return;
    }

    const newUser:UserRole = {
      userId: this.userForm.value.nameInput,
      roleId: this.selectedRole.id!,
      role: null
    }

    this.httpClient.post<UserRole>(this.apiUrl + "UserRole", newUser).subscribe(
      (res: any) => {
        this.selectedRoleUsers.push(res);
        this.userForm.reset();
      }
    );

    
  }

  removeUser(user:UserRole){
    this.httpClient.delete<UserRole>(this.apiUrl + "UserRole/" + user.id).subscribe(
      (res: UserRole) => {
        this.selectedRoleUsers.splice(this.selectedRoleUsers.indexOf(user), 1);
      }
    );
  }

  getPopupTitle(): any{
    return this.translate.instant('ADMIN.' + this.popUpFunction);
  }

  getPopupText(): any{
    return this.translate.instant('ADMIN.' + this.popUpFunction + ' TEXT');
  }
}
