<div class="popup-container" [ngClass]="{'active': isPopupOpen}">
    <div class="popup">
        <svg (click)="closePopup()" class="close-icon" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 28.062 28.062">
            <path class="cls-1" d="M5.469,30.371l24.4-24.4,3.66,3.66-24.4,24.4Z" transform="translate(-5.469 -5.969)"/>
            <path class="cls-1" d="M9.127,5.97l24.407,24.4-3.66,3.661L5.467,9.631Z" transform="translate(-5.469 -5.969)"/>
        </svg>

        <span class="title">{{getPopupTitle()}}</span>
        
        <span class="text">{{getPopupText()}}<br>{{ 'ADMIN.CONFIRM' | translate }}</span>

        <form [formGroup]="elementForm">
            <!--Code-->
            <div class="field-container">
                <label for="codeInput">{{ 'ADMIN.CODE' | translate }}</label>
                <input #codeInput type="text" id="codeInput" formControlName="codeInput" placeholder="{{ 'ADMIN.CODE' | translate }}">
            </div>
            
            <!--Name-->
            <div class="field-container">
                <label for="nameInput">{{ 'ADMIN.NAME' | translate }} {{getLanguage()}}</label>
                <input type="text" id="nameInput" class="" formControlName="nameInput" placeholder="{{ 'ADMIN.NAME' | translate }} {{getLanguage()}}"
                    (blur)="setFormName()">
            </div>
            
            <!--Name EN-->
            <div class="field-container" *ngIf="getLanguage() != 'EN'">
                <label for="nameENInput">{{ 'ADMIN.NAME' | translate }} EN</label>
                <input type="text" id="nameENInput" class="" formControlName="nameENInput" placeholder="{{ 'ADMIN.NAME' | translate }} EN">
            </div>
            
            <!--Name FR-->
            <div class="field-container" *ngIf="getLanguage() != 'FR'">
                <label for="nameFRInput">{{ 'ADMIN.NAME' | translate }} FR</label>
                <input type="text" id="nameFRInput" class="" formControlName="nameFRInput" placeholder="{{ 'ADMIN.NAME' | translate }} FR">
            </div>
            
            <!--Name SV-->
            <div class="field-container" *ngIf="getLanguage() != 'SV' && (elementType === 'Function' || elementType === 'MissingCause')">
                <label for="nameSVInput">{{ 'ADMIN.NAME' | translate }} SV</label>
                <input type="text" id="nameSVInput" class="" formControlName="nameSVInput" placeholder="{{ 'ADMIN.NAME' | translate }} SV">
            </div>
            
            <!--Name DE-->
            <div class="field-container" *ngIf="getLanguage() != 'DE' && (elementType === 'Function' || elementType === 'MissingCause')">
                <label for="nameDEInput">{{ 'ADMIN.NAME' | translate }} DE</label>
                <input type="text" id="nameDEInput" class="" formControlName="nameDEInput" placeholder="{{ 'ADMIN.NAME' | translate }} DE">
            </div>
            
            <!--Name NL-->
            <div class="field-container" *ngIf="getLanguage() != 'NL'">
                <label for="nameNLInput">{{ 'ADMIN.NAME' | translate }} NL</label>
                <input type="text" id="nameNLInput" class="" formControlName="nameNLInput" placeholder="{{ 'ADMIN.NAME' | translate }} NL">
            </div>
            
            <!--Name PT-->
            <div class="field-container" *ngIf="getLanguage() != 'PT' && (elementType === 'Function' || elementType === 'MissingCause')">
                <label for="namePTInput">{{ 'ADMIN.NAME' | translate }} PT</label>
                <input type="text" id="namePTInput" class="" formControlName="namePTInput" placeholder="{{ 'ADMIN.NAME' | translate }} PT">
            </div>
        </form>

        <app-demo-button
            text="{{ 'ADMIN.' + popupFunction | translate }}"
            color="tertiary"
            (clickEvent)="onFormSubmit()"
            [disable]="!elementForm.valid || isSavingData || !isFormValid"></app-demo-button>
    </div>
</div>

<div class="spinner-container" *ngIf="isSavingData">
    <mat-spinner></mat-spinner>
</div>