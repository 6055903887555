import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviationTag } from 'src/app/models/deviationTag.model';
import { FactoryView } from 'src/app/models/factoryViewModel.model';
import { environment } from 'src/environments/environment';
import * as Diacritics from 'diacritics';

@Component({
  selector: 'app-administrate-list-tags',
  templateUrl: './administrate-list-tags.component.html',
  styleUrl: './administrate-list-tags.component.less'
})
export class AdministrateListTagsComponent {

  apiUrl = environment.API_URL;

  selectedFactoryId:number | null = null;
  authorizedFactories:FactoryView[] = [];
  
  tagsList:any[] = [];
  tagsLoaded = false;

  newTagText = "";
  tagFilter = "";
  errorMessage = "";

  constructor(
    private httpClient:HttpClient,
    private translate: TranslateService) {
  }

  ngOnInit(){
    this.getAuthorizedFactories(4);
  }

  selectFactory(){
    if(this.selectedFactoryId != null){
      this.getTagsList(this.selectedFactoryId);
    }
  }

  getAuthorizedFactories(page?:number){
    this.httpClient.get<FactoryView[]>(`${this.apiUrl}Factory/ByPageType/${page}`).subscribe(
      (res: FactoryView[]) => {
        this.authorizedFactories = res;
        this.selectedFactoryId = res[0].id != null ? res[0].id : null;
        this.selectFactory();
      }
    );
  }

  getTagsList(factoryId:number){
    if(factoryId == null) { return;}
    
    this.tagsList = [];
    this.tagsLoaded = false;
    
    this.httpClient.get<any[]>(`${this.apiUrl}Deviation/Tag/ByFactoryId/${factoryId}`).subscribe(
      (res: any[]) => {
        this.tagsLoaded = true;
        this.tagsList = this.sortTags(res);
      }
    );
  }

  changeVisibility(tag:DeviationTag, event?:any, callback?:(tag:DeviationTag) => void){
    event?.preventDefault();
    event?.stopPropagation();
    if(tag == null) {return;}

    tag.disabled = !tag.disabled;

    this.httpClient.put<DeviationTag>(this.apiUrl + "Deviation/Tag", tag).subscribe(
      (res: DeviationTag) => {
        this.tagsList.splice(this.tagsList.findIndex(x=> x.id === res.id), 1, res);
        this.tagsList = this.sortTags(this.tagsList);
        if(callback) { callback(res);}
      }
    );
  }

  sortTags(tags:DeviationTag[]): DeviationTag[]{
    return tags.sort((a, b) => {
      // Sort by enabled/disabled first
      if (a.disabled !== b.disabled) {
        return a.disabled ? 1 : -1;
      }
      // Then sort alphabetically by name
      return a.name.localeCompare(b.name);
    });
  }

  createNewTag(){    
    if(this.selectedFactoryId == null || this.newTagText === "") {return;}

    if(this.tagsList.find(x=>x.name === this.newTagText) != null){
      this.errorMessage = "ALREADY EXIST";
      return;
    }

    const newTag = new DeviationTag(this.selectedFactoryId, this.newTagText, 0, false);

    this.httpClient.post<DeviationTag>(this.apiUrl + "Deviation/Tag", newTag).subscribe(
      (res: DeviationTag) => {
        this.tagsList.push(res);
        this.tagsList = this.sortTags(this.tagsList);
        this.newTagText = "";
      }
    );
  }

  tagFiltered():DeviationTag[]{
    return this.tagsList.filter(x=>this.notSensitive(x.name).includes(this.notSensitive(this.tagFilter)))
  }

  notSensitive(s:string | null | undefined){
    if(s==null) { return "";}
    return Diacritics.remove(s).toLowerCase();
  }
}
