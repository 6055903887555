import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-direction-arrow',
  templateUrl: './direction-arrow.component.html',
  styleUrls: ['./direction-arrow.component.less']
})
export class DirectionArrowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
