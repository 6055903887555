export class DeviationTag {

    id:number;
    factoryId:number;
    name:string;
    disabled:boolean;

    constructor(
        factoryId:number,
        name:string,
        id:number,
        disabled:boolean) {

        this.name = name;
        this.factoryId = factoryId;
        this.id = id;
        this.disabled = disabled;
    }
}