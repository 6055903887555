
<div class="reset-input" *ngIf="!isDisabled">
  <svg *ngIf="filterInputText != ''" (click)="clear()" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 28.062 28.062">
    <path class="cls-1" d="M5.469,30.371l24.4-24.4,3.66,3.66-24.4,24.4Z" transform="translate(-5.469 -5.969)"/>
    <path class="cls-1" d="M9.127,5.97l24.407,24.4-3.66,3.661L5.467,9.631Z" transform="translate(-5.469 -5.969)"/>
  </svg>
</div>

<div class="tooltip-container">
  <input matInput type="text" [(ngModel)]="filterInputText" (input)="filterChanged($event)" (focus)="onFocus()" autocomplete="off" *ngIf="!isDisabled" [ngClass]="{'warning' :warningFieldNotVoid}">
  <input matInput type="text" [(ngModel)]="filterInputText" (input)="filterChanged($event)" (focus)="onFocus()" (blur)="stopFocus($event)" autocomplete="off" *ngIf="isDisabled" disabled>
  
  <!--Warning and errors-->
  <div class="warning-container" *ngIf="warningFieldNotVoid && !isFilterInputFocus">
    <mat-icon class="mat-icon-rtl-mirror error">{{'error'}}</mat-icon>
    <span class="error-message">{{"SEARCH RUNNING" | translate}}</span>
  </div>

  <div class="field-options">
    <mat-icon class="mat-icon-rtl-mirror small" (click)="isSettingsOpen = !isSettingsOpen">{{'settings'}}</mat-icon>
    <div class="options-container" *ngIf="isSettingsOpen">
      <div class="option option-level">
        {{"LEVEL SETTINGS EXPLANATION"| translate}}
        <mat-select [(value)]="selectedLevelOption" (selectionChange)="changeOptionLevel($event)">
          <mat-option [value]="1">{{"LEVEL" | translate}} 1</mat-option>
          <mat-option [value]="2">{{"LEVEL" | translate}} 2</mat-option>
          <mat-option [value]="3">{{"LEVEL" | translate}} 3</mat-option>
          <mat-option [value]="4">{{"LEVEL" | translate}} 4</mat-option>
        </mat-select>
      </div>
      <div class="option option-code">
        {{"CODE SETTINGS EXPLANATION" | translate}}
        <mat-slide-toggle [(ngModel)]="showCode" (change)="toggleShowCode($event)">{{"SHOW CODE" | translate}}</mat-slide-toggle>
      </div>
    </div>
  </div>
</div>

@if (treeType === "Function") {
  <app-demo-button text="{{'ADMIN.ADD FUNCTION LEVEL1' | translate}}" color="tertiary" type="btn-small" (clickEvent)="addNode($event)"></app-demo-button>
}

<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="active" *ngIf="!isDisabled" (mousedown)="cancelBlur()">  
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [ngClass]="{'active': selectedFaultType?.id == node.name.split('/', 1)[0] || selectedFunction?.id == node.name.split('/', 1)[0] || selectedResponsible?.id == node.name.split('/', 1)[0] || selectedMissingCause?.id == node.name.split('/', 1)[0], 'active-path':isSelectedPath(node), 'to-be-selected': false}" (click)="selectNode(node)" (keyup.enter)="selectNode(node)" tabIndex="0" (keyup.arrowleft)="treeControl.collapse(node)" (keyup.arrowright)="treeControl.expand(node)">
      @if (treeControl.isExpandable(node)) {
        <button mat-icon-button matTreeNodeToggle type='button'
                [attr.aria-label]="'Toggle ' + node.name" tabindex="-1">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
      } @else {
        <!-- use a disabled button to provide padding for tree leaf -->
        <button mat-icon-button disabled type='button'></button>
      }
      <span [ngClass]="{disabled: !isNodeEnabled(node)}">{{getNodeDisplayName(node)}}</span>
      <button *ngIf="!isLastLevel(node)" mat-icon-button type='button' class="add" tabindex="-1">
        <mat-icon class="mat-icon-rtl-mirror" (click)="addNode($event, node)">{{'add'}}</mat-icon>
      </button>
      <button *ngIf="!isReadOnly(node)" mat-icon-button type='button' class="edit" tabindex="-1">
        <mat-icon class="mat-icon-rtl-mirror" (click)="editNode($event, node)">{{'edit'}}</mat-icon>
      </button>
      <button *ngIf="!isReadOnly(node)" mat-icon-button type='button' class="visibility" tabindex="-1">
        <mat-icon class="mat-icon-rtl-mirror visibility" (click)="toggleNodeEnabled($event, node)">
          {{isNodeEnabled(node) ? 'visibility' : 'visibility_off'}}
        </mat-icon>
      </button>
    </mat-tree-node>
  </mat-tree>