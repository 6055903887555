import { HttpClient } from '@angular/common/http';
import { ThisReceiver } from '@angular/compiler';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
import { Factory } from 'src/app/models/factory.model';
import { Role } from 'src/app/models/role.model';
import { UserRole } from 'src/app/models/userRole.model';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-roles-rights-settings-page',
  templateUrl: './roles-rights-settings-page.component.html',
  styleUrls: ['./roles-rights-settings-page.component.less']
})
export class RolesRightsSettingsPageComponent implements OnInit {

  @ViewChild('nameInput') nameInput!: ElementRef;

  @HostListener('window:keydown.escape') escapeEvent() { this.closePopUp(); }

  apiUrl:string =  environment.API_URL;
  isAuth:boolean = false;
  isLoadingAuth:boolean = true;

  factories!:Factory[];
  roles!:Role[];

  roleForm = new FormGroup({
    nameInput : new FormControl(''),
    factoryInput : new FormControl('')
  });

  popUpFunction = "";
  popUpElement!: Role | null;

  constructor(private httpClient:HttpClient, private translate: TranslateService, private authService:MsalService) { }

  ngOnInit(): void {
    this.isAuthorized();
  }

  isAuthorized(){
    this.httpClient.get<UserRole[]>(this.apiUrl + "UserRole/ByUserId/" + encodeURIComponent(this.authService.instance.getActiveAccount()?.username!)).subscribe(
      (res: UserRole[]) => {
        console.log("list of role me",res);
        this.isLoadingAuth = false;

        res.forEach((userRole:UserRole) => {
          if(userRole.role?.administrateData)
          {
            this.isAuth = true;
          }
        });

        if(this.isAuth){
          this.getFactories();
          this.getRoles();
        }
      }
    );
  }

  getFactories(){
    this.httpClient.get<Factory[]>(this.apiUrl + "Factory").subscribe(
      (res: any) => {
        this.factories = res;
      }
    );
  }

  getRoles(){
    this.httpClient.get<Role[]>(this.apiUrl + "Role").subscribe(
      (res: any) => {
        this.roles = res;
        console.log(res);
      }
    );
  }

  openPopUp(f: string, element?:Role){
    this.roleForm.reset();
    this.popUpFunction = f;

    if(element != null)
    {
      this.popUpElement = element;
    }

    if(f === "MODIFY"){
      console.log(element);
      this.roleForm.setValue({
        nameInput: element?.name || "",
        factoryInput: element?.factory?.name || ""
      });
    }

    setTimeout(()=>{
      this.nameInput.nativeElement.focus();
    },100);
  }

  closePopUp(){
    this.popUpElement = null;
    this.popUpFunction = "";
  }

  onCheckChange(role:any, type:string, event:any) {
    switch(type) { 
      case 'administrateData': { 
         role.administrateData = !role.administrateData;
         break; 
      } 
      case 'createDeviation': { 
        role.createDeviation = !role.createDeviation;
        break; 
      }
      case 'readDeviation': { 
        role.readDeviation = !role.readDeviation;
        break; 
      } 
      case 'modifyDeviation': { 
          role.modifyDeviation = !role.modifyDeviation;
          break; 
      }
      case 'modifySolvedDeviation': { 
        role.modifySolvedDeviation = !role.modifySolvedDeviation;
        break; 
      } 
      case 'solveDeviation': { 
        role.solveDeviation = !role.solveDeviation;
        break; 
      } 
      case 'manageDeviationTags': { 
        role.manageDeviationTags = !role.manageDeviationTags;
        break; 
      } 
      case 'readProductQualityStatus': { 
          role.readProductQualityStatus = !role.readProductQualityStatus; 
          break; 
      }
      case 'readMissingPart': { 
        role.readMissingPart = !role.readMissingPart;
        break; 
      } 
      case 'missingPartManagement': { 
        role.missingPartManagement = !role.missingPartManagement; 
        break; 
      } 
      case 'validateMissingPart': { 
        role.validateMissingPart = !role.validateMissingPart; 
        break; 
      }
      case 'createMissingPart': { 
        role.createMissingPart = !role.createMissingPart; 
        break; 
      }
      case 'checkCheckpoint': {
        role.checkCheckpoint = !role.checkCheckpoint; 
        break; 
      } 
      case 'readSupplierQuality': {
        role.readSupplierQuality = !role.readSupplierQuality; 
        break; 
      } 
      case 'createSupplierQuality': {
        role.createSupplierQuality = !role.createSupplierQuality; 
        break; 
      } 
      case 'modifySupplierQuality': {
        role.modifySupplierQuality = !role.modifySupplierQuality; 
        break; 
      } 
      default: { 
         //statements; 
         break; 
      } 
    }
    console.log("new check role", role);
    this.httpClient.put<Role>(this.apiUrl + "Role", role).subscribe(
      (res: any) => {
        console.log("result");
      }
    );
    
  }

  addRole(){
    if(this.roles.find((x:any)=>x.name === this.roleForm.value.nameInput!))
    {
      return;
    }

    const newRole:Role = {
      name: this.roleForm.value.nameInput!,      
      factory: null,
      factoryId: this.getFactoryByName(this.roleForm.value.factoryInput!).id!,
      administrateData: false,
      createDeviation: false,
      readDeviation: false,
      modifyDeviation: false,
      solveDeviation: false,
      manageDeviationTags: false,
      readProductQualityStatus: false,
      readMissingPart:false,
      missingPartManagement:false,
      validateMissingPart:false,
      createMissingPart:false,
      checkCheckpoint:false,
      modifySolvedDeviation:false,
      readSupplierQuality:false,
      createSupplierQuality:false,
      modifySupplierQuality:false,
    }

    this.httpClient.post<Role>(this.apiUrl + "Role", newRole).subscribe(
      (res: any) => {
        res.factory = this.factories.find((x:any)=>x.name === this.roleForm.value.factoryInput!);
        this.roles.push(res);
        this.closePopUp();
      }
    );

    
  }

  modifyRole(){
    if(this.popUpElement == null)
    {
      return;
    }
  
    this.popUpElement.name = this.roleForm.value.nameInput!;
    this.popUpElement.factory = null;
    this.popUpElement.factoryId = this.getFactoryByName(this.roleForm.value.factoryInput!).id!;

    this.httpClient.put<Role>(this.apiUrl + "Role", this.popUpElement).subscribe(
      (res: any) => {
        if(this.popUpElement)
        {
          this.popUpElement.factory = this.getFactoryByName(this.roleForm.value.factoryInput!);
        }

        this.closePopUp();
      }
    ); 
    
    
  }

  deleteRole(){
    if(this.popUpElement == null)
    {
      return;
    }

    this.httpClient.delete<Role>(this.apiUrl + "Role/" + this.popUpElement.id).subscribe(
      (res: any) => {
        if(this.popUpElement)
        {
          this.roles.splice(this.roles.indexOf(this.popUpElement), 1);
          this.closePopUp();
        }
      }
    ); 
  }
  
  getFactoryByName(displayName:string):Factory{
    return this.factories.find(x => x.name === displayName)!; 
  }

  getPopupTitle(): any{
    return this.translate.instant('ADMIN.' + this.popUpFunction);
  }

  getPopupText(): any{
    return this.translate.instant('ADMIN.' + this.popUpFunction + ' TEXT');
  }

}
