<div class="page-container">
    <div class="options-container">
        <mat-select id="factory" [(ngModel)]="selectedFactoryId" (selectionChange)="selectFactory()">
            <mat-option *ngFor="let factory of authorizedFactories" [value]="factory.id">{{factory.name}}</mat-option>
        </mat-select>
        <mat-select id="line" [(ngModel)]="selectedLineId" (selectionChange)="selectLine()">
            <mat-option *ngFor="let line of authorizedLines" [value]="line.id">{{line.name}}</mat-option>
        </mat-select>
    </div>

    <div class="main-container">
        <div class="tree-container">
            <span class="title">{{ 'FAULT TYPES' | translate }}</span>
            <app-tree-data id="faultTypeField"
                            [faultTypesList]="faultTypesList"
                            [currentSelectedFaultType]="selectedFaultType"
                            [treeType]="'FaultType'"
                            (toggleEnabledEvent)="toggleEnabled($event)"
                            (selectEvent)="selectFaultType($event)"
                            (addEvent)="openAddFaultTypePopup($event)"
                            (editEvent)="openEditFaultTypePopup($event)">
            </app-tree-data>
        </div>

        <div class="lines-container" *ngIf="selectedFaultType != null">
            <span class="title">{{ 'ADMIN.PRODUCTION LINES' | translate }}</span>
            <div class="scrollable-container">
                <div class="factory-container" *ngFor="let factory of authorizedFactories">
                    <div class="line-container" *ngIf="factory.productionLines.length > 0">
                        <div class="checkbox-container">
                            <input type="checkbox" [id]="'checkbox-' + factory.id + '-all-lines'" [checked]="areAllLinesChecked(factory.id!)" (change)="onCheckAllLinesChange(factory.id!, $event)">
                            <label [for]="'checkbox-' + factory.id + '-all-lines'">{{factory.name}} - {{"ALL LINES" | translate}}</label>
                        </div>
                    </div>
                    <div class="line-container" *ngFor="let line of factory.productionLines">
                        <div class="checkbox-container">
                            <input type="checkbox" [id]="'checkbox-' + line.id" [checked]="linkedLines.has(line.id!)" (change)="onCheckChange(line.id!, $event)">
                            <label [for]="'checkbox-' + line.id">{{factory.name}} - {{line.name}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-tree-element-popup [isPopupOpen]="hasFaultTypeToChange"
                        [selectedFaultType]="faultTypeToModify"
                        [selectedLineId]="selectedLineId ?? -2"
                        [parentFaultTypeView]="selectedParent"
                        [elementType]="'FaultType'"
                        (closeFaultTypePopupEvent)="closeFaultTypePopup($event)">
</app-tree-element-popup>

<div class="spinner-container" *ngIf="isSavingData">
    <mat-spinner></mat-spinner>
</div>