export class MissingCause {

    id = 0;
    code = "";
    level1 = "";
    level2 = "";
    level3 = "";
    nameEN = "";
    nameFR = "";
    nameSV = "";
    nameDE = "";
    nameNL = "";
    namePT = "";
    disabled = false;
    parentId?: number | null = null;

    constructor() {}
}