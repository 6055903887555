interface Translation {
    nameEN: string;
    nameFR: string;
    nameSV: string;
    nameDE: string;
    nameNL: string;
    namePT: string;
}

interface Levels {
    level1: string;
    level2: string;
    level3: string;
}

export class MissingCause {

    id:number;
    code:string;
    level1:string;
    level2:string;
    level3:string;
    nameEN:string;
    nameFR:string;
    nameSV:string;
    nameDE:string;
    nameNL:string;
    namePT:string;
    disabled:boolean;

    constructor(   
        id:number,
        code:string,
        levels:Levels,
        names:Translation,
        disabled:boolean) {

        this.id = id;
        this.code = code;
        this.level1 = levels.level1;
        this.level2 = levels.level2;
        this.level3 = levels.level3;
        this.nameEN = names.nameEN;
        this.nameFR = names.nameFR;
        this.nameSV = names.nameSV;
        this.nameDE = names.nameDE;
        this.nameNL = names.nameNL;
        this.namePT = names.namePT;
        this.disabled = disabled;
    }
}