<div class="page-container">
    <div class="options-container">
        <mat-select id="factory" [(ngModel)]="selectedFactoryId" (selectionChange)="selectFactory()">
            <mat-option *ngFor="let factory of authorizedFactories" [value]="factory.id">{{factory.name}}</mat-option>
        </mat-select>
        <mat-select id="line" [(ngModel)]="selectedLineId" (selectionChange)="selectLine()">
            <mat-option *ngFor="let line of authorizedLines" [value]="line.id">{{line.name}}</mat-option>
        </mat-select>
        <mat-select id="faultType" [(ngModel)]="selectedFaultTypeId" (selectionChange)="selectFaultType()">
            <mat-option [value]="-1">{{"ALL" | translate}}</mat-option>
            <mat-option *ngFor="let faultType of faultTypesList" [value]="faultType.id">{{getFaultTypeRank2Name(faultType)}}</mat-option>
        </mat-select>
    </div>

    <div class="main-container">
        <div class="tree-container">
            <span class="title">{{ 'RESPONSIBLES' | translate }}</span>
            <app-tree-data id="responsibleField"
                            [responsiblesList]="responsiblesList"
                            [currentSelectedResponsible]="selectedResponsible"
                            [treeType]="'Responsible'"
                            (toggleEnabledEvent)="toggleEnabled($event)"
                            (selectEvent)="selectResponsible($event)"
                            (addEvent)="openAddResponsiblePopup($event)"
                            (editEvent)="openEditResponsiblePopup($event)">
            </app-tree-data>
        </div>

        <div class="lines-container" *ngIf="selectedResponsible != null">
            <span class="title">{{ 'ADMIN.PRODUCTION LINES' | translate }}</span>
            <div class="scrollable-container">
                <div class="factory-container" *ngFor="let factory of authorizedFactories">
                    <div class="line-container" *ngIf="factory.productionLines.length > 0"
                        [ngClass]="{ 'selected': selectedPlrFactoryId === factory.id }"
                        (click)="selectPlrFactory(factory.id!)">
                        <mat-icon class="mat-icon-rtl-mirror">{{factoryHasLinkedFaultType(factory.id!) ? 'check' : ''}}</mat-icon>
                        <span>{{factory.name}} - {{"ALL LINES" | translate}}</span>
                    </div>
                    <div class="line-container" *ngFor="let line of factory.productionLines"
                        [ngClass]="{ 'selected': selectedPlrLineId === line.id }"
                        (click)="selectPlrLine(line.id!)">
                        <mat-icon class="mat-icon-rtl-mirror">{{hasLinkedFaultType(line.id!) ? 'check' : ''}}</mat-icon>
                        <span>{{factory.name}} - {{line.name}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="fault-type-container" *ngIf="selectedPlrFactoryId != null || selectedPlrLineId != null">
            <span class="title">{{ 'FAULT TYPES' | translate }}</span>
            <div class="scrollable-container">
                <div class="checkbox-container">
                    <input type="checkbox" [id]="'checkbox-all-fault-types'" [checked]="areAllFaultTypesChecked()" (change)="onCheckChange(-1, $event)"
                        [ngClass]="{ 'indeterminate': isAllFaultTypeIndeterminate() }">
                    <label [for]="'checkbox-all-fault-types'">{{"ALL" | translate}}</label>
                </div>
                <div class="checkbox-container" *ngFor="let ft of plrFaultTypesList">
                    <input type="checkbox" [id]="'checkbox-' + ft.id" [checked]="isFaultTypeChecked(ft.id)" (change)="onCheckChange(ft.id!, $event)"
                        [ngClass]="{ 'indeterminate': isFaultTypeIndeterminate(ft.id) }">
                    <label [for]="'checkbox-' + ft.id">{{getFaultTypeRank2Name(ft)}}</label>
                </div>
            </div>
        </div>
    </div>
</div>

<app-tree-element-popup [isPopupOpen]="hasResponsibleToChange"
                        [selectedElement]="responsibleToModify"
                        [selectedLineId]="selectedLineId ?? -2"
                        [selectedFaultTypeId]="selectedFaultTypeId ?? -1"
                        [parentId]="selectedParentId"
                        [elementType]="'Responsible'"
                        (closeResponsiblePopupEvent)="closeResponsiblePopup($event)">
</app-tree-element-popup>

<div class="spinner-container" *ngIf="isSavingData">
    <mat-spinner></mat-spinner>
</div>